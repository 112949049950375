<template>
  <div class="container">
    <!-- 主体区域 -->
    <router-view></router-view>
    <!-- 底部tabbar -->
    <van-tabbar ref="tabbar" v-show="showBtn" v-model="active">
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/my">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.active1 : icon.inactive1" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: require('@/assets/images/icon_selected_the query@3x.png'),
        inactive: require('@/assets/images/icon_disabled_the query@3x.png'),
        active1: require('@/assets/images/icon_selected_me@3x.png'),
        inactive1: require('@/assets/images/icon_disabled_me@3x.png'),

      },
      clientHeight: document.documentElement.clientHeight,
      showBtn: true,  // 控制按钮盒子显示隐藏

    };
  },
  methods: {
    appSource() {
      const u = navigator.userAgent, app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        this.$refs.tabbar.$el.style.height = '90px'
      } else {
       this.$refs.tabbar.$el.style.height = '65px'
      }
    }
  },
  mounted() {
    this.appSource()
    window.onresize = () => {
      if (this.clientHeight > document.documentElement.clientHeight) {
        this.showBtn = false
      } else {
        this.showBtn = true
      }
    }
  },
  watch: {
    $route: {
      handler: function () {
        switch (this.$route.path) {
          case '/':
            this.active = 0
            break
          case '/my':
            this.active = 1
            break
        }
      },
      immediate: true
    }

  }

}
</script>

<style lang="less" scoped>
.container {
  /deep/ .van-tabbar-item__icon {
    width: 34px;
    height: 34px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .van-tabbar-item__text {
    margin-bottom: 10px;
  }
}
</style>